if (document.querySelector('.points_slider')) {
    jQuery(document).ready(function(){
        jQuery('.slider').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            arrows: true,
            fade: false,
            dots: false,
            nextArrow: '<button type="button" class="slick-next" role="button" aria-label="next slide"><i class="fal fa-chevron-right"></i></button>',
            prevArrow: '<button type="button" class="slick-prev" role="button" aria-label="previous slide"><i class="fal fa-chevron-left"></i></button>',
            responsive: [
                {
                  breakpoint: 768,
                  settings: 'unslick'
                }
            ],
        });
        // jQuery('.slick-arrow').appendTo('.arrow-box')
    }
    );

    // slider responsive: unslick() on small screens, reinit and add setting object on large

    const settings = {
        // default settings
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        fade: false,
        dots: false,
        nextArrow: '<button type="button" class="slick-next" role="button" aria-label="next slide"><i class="fal fa-chevron-right"></i></button>',
        prevArrow: '<button type="button" class="slick-prev" role="button" aria-label="previous slide"><i class="fal fa-chevron-left"></i></button>',
        responsive: [
            {
              breakpoint: 768,
              settings: 'unslick'
            }
        ],
   };
   
   const sl =  jQuery('.slider').slick(settings);
         
   jQuery(window).on('resize', function() {
      if( jQuery(window).width() > 420 &&  !sl.hasClass('slick-initialized')) {
            jQuery('.slider').slick(settings);
       }
   });
    //end responsivness

    jQuery('.points_slider').on('setPosition', function () {
        jQuery(this).find('.slick-slide').height('auto');
        var slickTrack = jQuery(this).find('.slick-track');
        var slickTrackHeight = jQuery(slickTrack).height();
        jQuery(this).find('.slick-slide').css('height', slickTrackHeight + 'px');
        });
}