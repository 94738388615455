if (document.querySelector('.partners_section')) {
    jQuery(document).ready(function(){
        if ( jQuery('.logos img').length > 6 ) {
            jQuery('.logos').slick({
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 1,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 1500,
                responsive:[
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }
    });
}