if (document.querySelector('.full_width_slider')) {
    jQuery(document).ready(function(){
        jQuery('.full_width_slider').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: '<button type="button" class="slick-next" role="button" aria-label="next slide"><i class="fal fa-chevron-right"></i></button>',
          prevArrow: '<button type="button" class="slick-prev" role="button" aria-label="previous slide"><i class="fal fa-chevron-left"></i></button>'
        });
    })
}