if (document.querySelector('.testimonials')) {
    jQuery(document).ready(function(){
        jQuery('.testimonials-slider').slick({
            centerMode: true,
            centerPadding: '360px',
            slidesToShow: 1,
            variableWidth: true,
            autoplay: true,
            slidesToScroll: 1,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            dots: false,
            arrows: false,
            responsive: [
            {
            breakpoint: 730,
            settings: {
                centerPadding: '50px',
            }
            },
            {
            breakpoint: 530,
            settings: {
                centerPadding: '20px',
            }
            },
        ]
        });
    });
}