/*--------------------------------------------*\
    Include Globally Useful JS Functions
\*--------------------------------------------*/

ThemeJS.Functions = (function ( $, root ) {

    function send_ajax_request ( args ) {
        args.data.nonce = ThemeJS.Variables.Ajax.nonce;
        $.post( ThemeJS.Variables.Ajax.url, args.data, function ( response ) {
            response = JSON.parse( response );
            if ( !response.hasOwnProperty( 'data' ) ) { response.data = {}; }
            if ( response.success && typeof args.success == 'function' ) {
                args.success( response.data );
            } else if ( !response.success && typeof args.error == 'function' ) {
                args.error( response.data );
            }
        });
    }

    function get_query_parameter( name, url ) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    
    return {
        send_ajax_request: send_ajax_request,
        get_query_parameter: get_query_parameter
    }

})(jQuery, this);

// Searchform - toggle popup and hide on start
jQuery('#searchForm #search-front-button').click(function(){
    jQuery('#searchForm form').fadeToggle();
});
jQuery('#searchForm #search-close-popup').click(function(){
    jQuery('#searchForm form').hide();
});
jQuery('#searchForm #search-close-popup').focusout(function(){
    jQuery('#searchForm form').hide();
});
jQuery('#searchForm form').hide();
// hide on clock outside
let ignoreClickOnMeElement = document.getElementById('searchForm');

document.addEventListener('click', function(event) {
    let isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
    if (!isClickInsideElement) {
        //Do something click is outside specified element
        jQuery('#searchForm form').hide();
    }
});

// teams
// if (document.querySelector('.team_section')) {
//     document.querySelectorAll('.bio-popup-button').forEach(function (button, order) {
//         button.addEventListener("click", function (e) {
//             document.querySelectorAll('.team_section .popup')[order].style.display = 'initial';
//         })
//     })
//     document.querySelectorAll('.team_section .popup').forEach(function (popup) {
//         // close button
//         popup.children[0].children[0].addEventListener("click", function (e) {
//                 // this.parentNode.children[2].style.display = 'none';
//             this.parentNode.parentNode.style.display = 'none';
//         });
//         // close on click outside of popup
//         popup.addEventListener("click", function (e) {
//             if (e.target.classList[0] === 'popup') {
//                 this.parentNode.children[2].style.display = 'none';
//             }
//         })
//     })   
// }
(function($) {
    $(document).ready(function() {
        $("#teamModal").on('show.bs.modal', function( e ) {
            var button = $(e.relatedTarget), data = button.parents("article").data("info");
            
            $(".teamModal-contact, .teamModal-info", this).html('');

            $(".teamModal-image", this).html( data.image );
            if ( data.card.phone != "" ) {
                $(".teamModal-contact", this).append( `<div class="my-3"><i class="fal fa-phone"></i> ${ data.card.phone }</div>` );
            }

            if ( data.card.email != "" ) {
                $(".teamModal-contact", this).append( `<a href="mailto:${ data.card.email }" class="my-3 d-block"><i class="fal fa-envelope"></i> ${ data.card.email }</a>` );
            }
            
            if ( data.card.position != "" ) {
                $(".teamModal-info", this).append( `<p class="font-weight-bold mb-0">${ data.card.position }</p>` );
            }
            
            if ( data.card.first_name != "" ) {
                $(".teamModal-info", this).append( `<div class="name font-weight-bold h3">${ data.card.first_name } ${ data.card.last_name }</div>` );
            }
            
            if ( data.card.bio != "" ) {
                $(".teamModal-info", this).append( `<div class="bio">${ data.card.bio }</div>` );
            }
            
        });


        $(".sub-menu li").on('mouseenter mouseleave', function (e) {
            if ($('ul', this).length) {
                var elm = $('ul:first', this);
                var off = elm.offset();
                var l = off.left;
                var w = elm.width();
                var docH = $("body").height();
                var docW = $("body").width();
    
                var isEntirelyVisible = (l + w <= docW);
    
                if (!isEntirelyVisible) {
                    $(this).addClass('edge');
                } else {
                    $(this).removeClass('edge');
                }
            }
        });
    });
})(jQuery);
//JS for footer accordeon
    let acc = document.getElementsByClassName("accordion-button");
    let i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            let current = i;
            this.classList.toggle("active");
            jQuery(this).find(">:first-child").attr('aria-expanded', (_, attr) => attr == 'true' ? 'false' : 'true')
            let panel = this.nextElementSibling;
            jQuery(jQuery(`#accordion-${this.id[this.id.length-1]}`)[0]).stop().slideToggle()
        });
    }

    function showDesktopMenuAccQ(accQ) {
        if (accQ.matches) { // If media query matches
            document.querySelectorAll('.accordion').forEach((el) => {
                el.style.display = 'block'
            })
            // document.querySelectorAll('.accordion-button').forEach((el) => {
            //     el.style.display = 'none'
            // })
        } else {
            document.querySelectorAll('.accordion').forEach((el) => {
                el.style.display = 'none';
            })
            document.querySelectorAll('.accordion-button').forEach((el) => {
                el.style.display = 'block'
            })

        }
    }

    let accQ = window.matchMedia("(min-width: 768px)")
    showDesktopMenuAccQ(accQ);
    accQ.addListener(showDesktopMenuAccQ) // Attach listener function on state changes

    function matchFooterQuerry(q) {
    if (q.matches) { //if less
        
    } else {
        jQuery('.navigation-box').show()
    }
}
let isPseudoAfterButtomAppended = false;

function createPseudoAfterButtom() {
    if (isPseudoAfterButtomAppended === false) {
    
        document.querySelectorAll('.navigation-primary .menu-item-has-children').forEach(function (el) {
            var p = document.createElement("p");
            p.innerHTML = '▼'
            p.classList.add('pseudo-after-accordion')
            el.appendChild(p)
        })
    
        document.querySelectorAll('.pseudo-after-accordion').forEach(function (el) {
            el.addEventListener('click', function () {
                el.innerHTML = el.innerHTML==='▼'?'▲':'▼';
                jQuery(el.parentElement.children[1]).stop().slideToggle()
                jQuery(el.parentElement).toggleClass("menu-open");
            })
        })
        isPseudoAfterButtomAppended = true
    }
};

function removeAccordion() {
    jQuery(".sub-menu").attr('style', '')
    document.querySelectorAll('.pseudo-after-accordion').forEach(function (el) {
        el.remove();
    })
}

( function ( jQuery ) {

    // smooth-scroll / toggle modal on click of id href
    jQuery( document ).on( 'click', 'a[href^="#"]', function ( e ) {
        let jQuerytarget = jQuery( jQuery(this).attr('href') );
        e.preventDefault();
        if ( jQuerytarget.hasClass('modal') ) {
            jQuerytarget.modal('toggle');
            return;
        }
        if ( jQuerytarget.length ) {
            jQuery('html, body').animate(
                { scrollTop: jQuerytarget.offset().top },
                500,
                function() {
                    jQuerytarget.focus();
                    if ( jQuerytarget.is(":focus") ) {
                        return false;
                    } else {
                        jQuerytarget.attr('tabindex','-1');
                        jQuerytarget.focus();
                    }
                }
            );
        }
    });

})(jQuery);

// // js to change H1 to H2 if it's not the first H1 on the page
// if (document.querySelectorAll('h1').length > 1) {
//     for (let i = 1; i < document.querySelectorAll('h1').length; i++) {
//         let innerText = document.querySelectorAll('h1')[i].innerText;
//         let h2 = document.createElement("h2");
//         h2.innerText = innerText;
//         document.querySelectorAll('h1')[i].replaceWith(h2)
//     }
// };

// fix searchform duplicate ids
if (document.querySelectorAll('#site-searchform').length > 1) {
    document.querySelector('#site-searchform').setAttribute('id', 'site-searchform-2')
    document.querySelector('#search-form-submit').setAttribute('id', 'search-form-submit-2')
    document.querySelector('#search_s').setAttribute('id', 'search_s-2')
    document.querySelector('#search-close-popup').setAttribute('id', 'search-close-popup-2')
}