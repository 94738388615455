(function($) {
    $(document).ready(function() {
        var canada = ThemeJS.Cookies.get( 'canada' );
        var newsletter = ThemeJS.Cookies.get( 'newsletter' );
        
        if ( $("#canada_popup").length > 0 && canada !== 'eh' ) {
            $("#canada_popup").modal('show');
        } else {
            if ( $("#newsletter_popup").length > 0 && newsletter !== 'shown' ) {
                setTimeout( ( function() { $("#newsletter_popup").modal('show'); } ), 10000 );
            }
        }

        $('#canada_popup').on('hidden.bs.modal', function (e) {
            ThemeJS.Cookies.set( { canada: { value: 'eh', expires: 365 } } );
        });

        $('#newsletter_popup').on('hidden.bs.modal', function (e) {
            ThemeJS.Cookies.set( { newsletter: { value: 'shown', expires: 365 } } );
        });
    });
})(jQuery);