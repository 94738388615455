if (document.querySelector('.description_page_content .accordion-container')) {
    document.querySelectorAll('.accordion-container .accordion-item').forEach(function (button, counter) {
        // aria-describedby code
        jQuery(button.children[0]).attr('aria-describedby', `accordion-title-${counter}`);
        jQuery(button.children[1]).attr('id', `accordion-title-${counter}`);
        button.addEventListener("click", function (e) {
            console.log(this)
            jQuery(this.children[2]).slideToggle();
            jQuery("button i", this).toggleClass('fa-plus').toggleClass('fa-minus');
            // this.children[0].innerHTML = this.children[0].innerHTML==='<i class="fas fa-plus"></i>'?'<i class="fas fa-minus"></i>':'<i class="fas fa-plus"></i>'
        })
    })
}

if (document.querySelector('.single-product .attributes-accordion-container')) {
    document.querySelector('.single-product .attributes-accordion-container #toggle-accordion').addEventListener("click", function (e) {
        jQuery(this.parentElement.children[1]).slideToggle();
            this.innerHTML = this.innerHTML==='<i class="fal fa-plus"></i>'?'<i class="fal fa-minus"></i>':'<i class="fal fa-plus"></i>'
    })
}