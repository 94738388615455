jQuery( document ).ready( function( $ ) {

    $( '.hamburger' ).on( 'click', () => {
        $( '.hamburger' ).toggleClass('is-active');
        $( '.navigation-box' ).toggleClass('active');
        $( 'body' ).toggleClass('menu-active');
    })
    
    $( document ).on( 'click', '.menu-item-has-children', function(e) {
        console.log(this)
        $( this ).toggleClass('is-active');
    })

}); 