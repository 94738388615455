if (document.querySelector('.cta')) {
    document.querySelectorAll('#contact-pop-up_button').forEach(function (button, order) {
        button.addEventListener("click", function (e) {
            document.querySelectorAll('.cta .popup')[order].style.display = 'initial';
        })
    })
    document.querySelectorAll('.cta .popup').forEach(function (popup) {
        // close button
        popup.children[0].children[0].addEventListener("click", function (e) {
                // this.parentNode.children[2].style.display = 'none';
            this.parentNode.parentNode.style.display = 'none';
        });
        // close on click outside of popup
        popup.addEventListener("click", function (e) {
            if (e.target.classList[0] === 'popup') {
                this.parentNode.children[2].style.display = 'none';
            }
        })
    })   
} 